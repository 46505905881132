import { profile as _profile } from '@gonfalon/bootstrap-data';
import { fromJS, Map } from 'immutable';

import { createMember } from 'utils/accountUtils';

export function getProfileInitialState(initialState: ReturnType<typeof _profile> = _profile()) {
  const state = Map({
    entity: undefined,
    lastFetched: null,
    isFetching: false,
  });
  const p = initialState;

  if (p) {
    return state.merge({
      lastFetched: Date.now(),
      entity: createMember(fromJS(p)),
    });
  }

  return state;
}
