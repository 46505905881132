import { ResourceSpecifier } from './ast';

export const resourceHumanStringMap: { [k in ResourceSpecifier['type']]: string } = {
  acct: 'Account',
  aiconfig: 'AI config',
  application: 'Application',
  'code-reference-repository': 'Code reference repository',
  destination: 'Destination',
  'domain-verification': 'Domain verification',
  env: 'Environment',
  experiment: 'Experiment',
  feature: 'Feature',
  flag: 'Flag',
  goal: 'Goal',
  holdout: 'Holdout',
  integration: 'Integration',
  layer: 'Layer',
  member: 'Member',
  metric: 'Metric',
  'metric-group': 'Metric group',
  'payload-filter': 'Payload filter',
  'pending-request': 'Pending request',
  proj: 'Project',
  'relay-proxy-config': 'Relay Proxy config',
  'release-pipeline': 'Release pipeline',
  role: 'Role',
  segment: 'Segment',
  'service-token': 'Service token',
  team: 'Team',
  template: 'Template',
  token: 'Token',
  user: 'User',
  webhook: 'Webhook',
  'context-kind': 'Context kind',
};

export function typeToHumanString(type: ResourceSpecifier['type']) {
  return resourceHumanStringMap[type];
}
