import {
  evaluateContextInstance,
  getContextInstances,
  getContextKindsByProjectKey,
  getContexts,
} from '@gonfalon/openapi';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';

export const useEvaluateContextInstance = createMutationHook(evaluateContextInstance);
export const contextInstancesQuery = createQueryOptions(getContextInstances);
export const contextKindsQuery = createQueryOptions(getContextKindsByProjectKey);
export const getContextVersionsQuery = createQueryOptions(getContexts);
