import { z } from 'zod';

function safeParseJSON(string: string) {
  try {
    return JSON.parse(string);
  } catch {
    return string;
  }
}

function searchParamsToValues(searchParams: URLSearchParams) {
  let params = {};
  for (const key of searchParams.keys()) {
    const values = searchParams.getAll(key).map(safeParseJSON);
    params = { ...params, [key]: values.length > 1 ? values : values[0] };
  }

  return params;
}

function makeSearchParamsObjSchema<Schema extends z.Schema>(schema: Schema) {
  return z.instanceof(URLSearchParams).transform(searchParamsToValues).pipe(schema);
}

export function arrayParam<T extends string | number | boolean | null, Schema extends z.ZodType<T>>(schema: Schema) {
  return z.union([z.any().array(), z.any().transform((x) => [x])]).pipe(schema.array());
}

// TODO: Move this to another package since it's not bound to the router really… @gonfalon/url?
export function parseSearchParams<Schema extends z.Schema>(searchParams: URLSearchParams, schema: Schema) {
  const searchParamsObjSchema = makeSearchParamsObjSchema(schema);
  const result = searchParamsObjSchema.safeParse(searchParams);

  if (!result.success) {
    return { ok: false, data: undefined } as const;
  }

  return { ok: true, data: result.data } as const;
}
