import { useEffect, useLayoutEffect, useRef } from 'react';

export function usePolling({
  enabled = false,
  interval = 1,
  callback,
}: {
  enabled: boolean;
  interval: number;
  callback: () => void;
}) {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined;

    if (enabled) {
      intervalId = setInterval(() => {
        callbackRef.current();
      }, interval * 1000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [enabled, interval]);
}
