import { ChangeEvent, Component } from 'react';
import { Navigate } from 'react-router';
import { enableCheckYourEmailEndpoint } from '@gonfalon/dogfood-flags';
import { toCheckYourEmail } from '@gonfalon/navigator';
import cx from 'clsx';
import { Label, TextField } from 'launchpad';

import { navigateToUnauthenticatedVerifyEmail } from 'actions/navigation';
import Restrict from 'components/Restrict';
import { SubmitButton } from 'components/ui/buttons/SubmitButton';
import { FieldError, Form, FormError, FormGroup, FormHint } from 'components/ui/forms';
import { WithFormEnhancerProps } from 'components/withForm';
import { LoginInfo } from 'utils/loginUtils';

import 'stylesheets/components/LoginForm.css';

export type LoginFormProps = WithFormEnhancerProps<LoginInfo> & {
  hiddenFields?: string[];
  requirePassword: boolean;
  beastMode?: boolean;
  redirect?: string;
  beastModeEmail?: string;
  isUnverifiedEmail?: boolean;
  submitButtonLabel?: string; // Overrides the default "Log in" label
  onBeastMode?: (info: LoginInfo, redirectPath: string) => void;
  onLogin?: (info: LoginInfo, redirectPath: string, authenticate: boolean) => Promise<void>;
  onLoginV2?: (info: LoginInfo) => Promise<void>;
  onGetLoginPage?: (info: LoginInfo, redirectPath?: string | undefined) => Promise<unknown>;
  setTimeToLoginEndTime?: (endTime: number) => void;
};

type StateProps = {
  password: string;
};

/* eslint-disable import/no-default-export */
export default class LoginForm extends Component<LoginFormProps, StateProps> {
  // To support password managers, we track the password state locally
  // until it is required to make sure the user does not have to re-enter it.
  state = {
    password: '',
  };

  passwordRef?: HTMLInputElement | null;

  componentDidUpdate(prevProps: LoginFormProps) {
    if (!prevProps.requirePassword && this.props.requirePassword) {
      setTimeout(() => this.passwordRef?.focus());
      if (this.state.password) {
        this.props.onChange('password', this.state.password);
      }
    }
  }

  render() {
    const { hiddenFields, requirePassword, modified, onBlur, onChange, onSubmit, beastMode, isUnverifiedEmail } =
      this.props;
    const passwordClasses = cx('fs-exclude', 'LoginForm-password', {
      'is-required': requirePassword,
    });

    const renderSubmitButtonLabel = (config: { requirePassword?: boolean; submitButtonLabel?: string }) => {
      if (config?.submitButtonLabel) {
        return config.submitButtonLabel;
      } else if (config?.requirePassword) {
        return 'Sign in';
      } else {
        return 'Continue';
      }
    };

    if (isUnverifiedEmail) {
      if (enableCheckYourEmailEndpoint()) {
        return <Navigate to={toCheckYourEmail()} state={{ email: modified.username }} />;
      } else {
        navigateToUnauthenticatedVerifyEmail(modified.username);
        return null;
      }
    }

    return (
      <Form onSubmit={onSubmit} onBlurCapture={onBlur} className="LoginForm" hasIncreasedErrorMargin>
        <FormError />

        {!hiddenFields?.includes('email') && (
          <FormGroup name="username">
            <Restrict
              isRestricted={!!beastMode}
              tooltip="You must reauthenticate your credentials for this email to manage your account"
              tooltipOptions={{
                rootElementStyle: {
                  display: 'block',
                },
              }}
              willDisable
            >
              <>
                <Label htmlFor="username">Email</Label>
                <TextField
                  required
                  id="username"
                  name="username"
                  data-test-id="username"
                  autoComplete="email"
                  value={modified.username}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('username', e.target.value)}
                />
              </>
            </Restrict>
            <FieldError className="error" name="username" />
          </FormGroup>
        )}

        <FormGroup
          name="password"
          className={passwordClasses}
          aria-hidden={!requirePassword}
          data-test-id="login-password"
        >
          <Label htmlFor="password">Password</Label>
          <TextField
            required={!!requirePassword}
            ref={(c) => {
              this.passwordRef = c;
            }}
            id="password"
            name="password"
            data-test-id="password"
            type="password"
            autoComplete="current-password"
            tabIndex={requirePassword ? 0 : -1}
            value={requirePassword ? modified.password : this.state.password}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              requirePassword ? onChange('password', e.target.value) : this.setState({ password: e.target.value })
            }
          />
          <FormHint>
            <a href="/forgot">Forgot your password?</a>
          </FormHint>
          <FieldError className="error" name="password" />
        </FormGroup>

        <FormGroup>
          <SubmitButton
            data-test-id="signin-button"
            disabled={false}
            fit
            loadingText={requirePassword ? 'Signing in' : undefined}
            className="LoginForm-submit"
          >
            {renderSubmitButtonLabel({ requirePassword, submitButtonLabel: this.props.submitButtonLabel })}
          </SubmitButton>
        </FormGroup>
      </Form>
    );
  }
}
