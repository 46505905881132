import { baseUri } from '@gonfalon/constants';
import { parseSearchParams } from '@gonfalon/router';
import type { Prettify, WithRequiredProperties } from '@gonfalon/types';
import { type InfiniteData, infiniteQueryOptions } from '@tanstack/react-query';
import type { FetchResponse } from 'openapi-fetch';
import invariant from 'tiny-invariant';
import { z } from 'zod';

import { makeQueryId } from './makeQueryId';
import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

type OperationContext = { signal: AbortSignal };

type Operation<T, O, M extends `${string}/${string}`, Input> = (
  input: Input,
  context: OperationContext,
) => Promise<FetchResponse<T, O, M>>;

type OperationData<T, O, M extends `${string}/${string}`> = NonNullable<FetchResponse<T, O, M>['data']>;

type PaginatedInput =
  | { query?: { offset?: number } }
  | { query?: { before?: number } }
  | { query?: { continuationToken?: string } };

type Offset<Input> = Input extends { query?: { offset?: number } } ? { offset: number } : never;
type Before<Input> = Input extends { query?: { before?: number } } ? { before: number } : never;
type ContinuationToken<Input> = Input extends { query?: { continuationToken?: string } }
  ? { continuationToken: string }
  : never;

type OperationPageParam<Input> = Offset<Input> | Before<Input> | ContinuationToken<Input>;

type WithRequiredPageParam<Input> = Input extends { query?: infer Q }
  ? Q extends { offset?: number }
    ? Input & { query: WithRequiredProperties<Q, 'offset'> }
    : Q extends { before?: number }
      ? Input & { query: WithRequiredProperties<Q, 'before'> }
      : Q extends { continuationToken?: string }
        ? Input & { query: WithRequiredProperties<Q, 'continuationToken'> }
        : never
  : never;

type QueryKey<Input> = readonly [{ type: string } & Input];

const dataPaginationSchema = z.object({
  _links: z.object({
    previous: z.object({ href: z.string() }).optional(),
    next: z.object({ href: z.string() }).optional(),
  }),
});

const inputPageParamSchema = z.union([
  z.object({
    offset: z.number(),
  }),
  z.object({
    before: z.number(),
  }),
  z.object({
    continuationToken: z.string(),
  }),
]);

/**
 * Create infinite query options for one of our OpenAPI operations.
 *
 * The query will be cached in a way to correctly encodes all dependencies to that function.
 * Importantly, the query key and query function will be intertwined correctly for you, and
 * your query will therefore be cached correctly.
 *
 * You may specify additional options in the package.
 *
 * You may also safely compose these options with additional options in consumer code, in
 * case you need to tweak certain aspects of the query for your specific use case.
 *
 */
export function createInfiniteQueryOptions<T, O, M extends `${string}/${string}`, Input extends PaginatedInput>(
  operation: Operation<T, O, M, Input>,
  options: Omit<
    ReturnType<
      typeof infiniteQueryOptions<
        OperationData<T, O, M>,
        unknown,
        InfiniteData<OperationData<T, O, M>, OperationPageParam<Input>>,
        QueryKey<Input>,
        OperationPageParam<Input>
      >
    >,
    'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam'
  > = {},
) {
  const type = makeQueryId(operation.name);

  function factory(args: WithRequiredPageParam<Input>) {
    return infiniteQueryOptions({
      queryKey: [{ type, ...args }] as const,
      queryFn: async ({ pageParam, ...context }) =>
        reactQueryResponseAdapter(
          operation(
            {
              ...args,
              query: {
                ...args.query,
                // Ensure the page param @tanstack/query gives us is passed to the operation
                ...(pageParam as OperationPageParam<Input>),
              },
            },
            context,
          ),
        ),

      initialPageParam: parseInitialPageParam(args),
      getNextPageParam: (lastPage) => parseLink(lastPage, 'next'),
      getPreviousPageParam: (firstPage) => parseLink(firstPage, 'previous'),

      ...options,
    });
  }

  function partialQueryKey(): readonly [{ type: string }];
  function partialQueryKey<const PartialArgs extends Partial<Input>>(
    partialArgs: PartialArgs,
  ): readonly [Prettify<{ type: string } & PartialArgs>];
  function partialQueryKey(
    /* We're OK with any here since the implementation signature is internal. */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    partialArgs?: any,
  ) {
    return [
      {
        ...partialArgs,
        type,
      },
    ] as const;
  }

  factory.partialQueryKey = partialQueryKey;

  return factory;
}

function parseInitialPageParam<Input extends PaginatedInput>(input: Input) {
  const parsed = inputPageParamSchema.safeParse(input.query);
  invariant(parsed.success, 'Invalid page param');

  let initialPageParam: OperationPageParam<Input> | undefined;

  if ('continuationToken' in parsed.data) {
    initialPageParam = {
      continuationToken: parsed.data.continuationToken,
    } as unknown as OperationPageParam<Input>;
  }

  if ('before' in parsed.data) {
    initialPageParam = { before: parsed.data.before } as unknown as OperationPageParam<Input>;
  }

  if ('offset' in parsed.data) {
    initialPageParam = { offset: parsed.data.offset } as unknown as OperationPageParam<Input>;
  }

  invariant(initialPageParam, 'Invalid page param');

  return initialPageParam;
}

function parseLink<Input>(pageData: unknown, direction: 'next' | 'previous') {
  const nextPage = dataPaginationSchema.safeParse(pageData);
  if (!nextPage.success) {
    return undefined;
  }

  const href = nextPage.data._links[direction]?.href;
  if (!href) {
    return undefined;
  }

  const url = new URL(href, baseUri());
  const params = parseSearchParams(new URLSearchParams(url.search), inputPageParamSchema);
  if (!params.ok) {
    return undefined;
  }

  if ('continuationToken' in params.data) {
    return { continuationToken: params.data.continuationToken } as unknown as OperationPageParam<Input>;
  }

  if ('before' in params.data) {
    return { before: params.data.before } as unknown as OperationPageParam<Input>;
  }

  if ('offset' in params.data) {
    return { offset: params.data.offset } as unknown as OperationPageParam<Input>;
  }

  return undefined;
}
