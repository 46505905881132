import { createTrackerForCategory, trackWithDetails } from '@gonfalon/analytics';
import { isImmutable, Record } from 'immutable';

import { GlobalDispatch } from 'reducers';
import { isEmail, isNotEmpty, validateRecord } from 'utils/validationUtils';

import { ImmutableServerError } from './httpUtils';

type LoginInfoProps = {
  accountId?: string; // Used for choose your organization page.
  username: string;
  password: string;
  oauth?: {
    provider: string;
    token: string;
  };
};

interface RedirectToSSOProps {
  redirectToSSOProvider: (location: string, destination: string) => void;
  res: Response;
  dispatch: GlobalDispatch;
  redirectPath: string;
  dispatchLoginRequiresMfa: (token: string) => void;
}

export class LoginInfo extends Record<LoginInfoProps>({
  accountId: undefined,
  username: '',
  password: '',
  oauth: undefined,
}) {}

export function createLoginInfo(props = {}) {
  return new LoginInfo(props);
}

type EmailNotVerifiedError = ImmutableServerError<{
  message: 'Email is not verified';
}>;

export function isEmailNotVerifiedError(error: unknown): error is EmailNotVerifiedError {
  return isImmutable(error) && error.get('status') === 422 && error.get('message') === 'Email is not verified';
}

export type SignInExperienceType =
  | 'Multi-account member Password'
  | 'Multi-account member SSO'
  | 'SSO'
  | 'Password'
  | undefined;
export interface TimeToLoginMetric {
  startTime?: number;
  endTime?: number;
  type?: SignInExperienceType;
  duration?: number;
}

export const trackSignUp = createTrackerForCategory('Sign Up Flow');
export const trackSignIn = createTrackerForCategory('Sign In Flow');
export const trackSignInPageLoaded = () => trackSignIn('Sign In Page Loaded');
export const trackChooseOrganizationPageLoaded = () => trackSignIn('Choose Organization Page Loaded');
export const trackChooseOrganizationButtonClicked = () => trackSignIn('Choose Organization Button Clicked');
export const trackSignInUIFlowComplete = (type: SignInExperienceType) =>
  trackSignIn('Sign in UI flow complete', { type });
export const trackSignInDuration = (timeToLoginMetric: TimeToLoginMetric) => {
  if (!timeToLoginMetric?.startTime) {
    return;
  }
  trackSignIn('Sign In Duration', {
    ...timeToLoginMetric,
    endTime: Date.now(),
    duration: Date.now() - timeToLoginMetric?.startTime,
  });
};

export const trackGDPRUserOptOut = ({ component }: { component: string }) =>
  trackWithDetails({ component, category: 'gdpr', event: 'did_opt_out_of_marketing_emails' });

export const validateLoginInfo = (record: LoginInfo) =>
  validateRecord(record, isNotEmpty('username'), isEmail('username'), isNotEmpty('password'));

export const validateLoginInfoWithEmailOnly = (record: LoginInfo) =>
  validateRecord(record, isNotEmpty('username'), isEmail('username'));

export async function redirectToSSO({
  res,
  redirectPath,
  redirectToSSOProvider,
  dispatchLoginRequiresMfa,
}: RedirectToSSOProps) {
  const location = res.headers && res.headers.get('Location');
  const isSignedURL = location && new URL(location)?.searchParams?.has('Signature');

  if (location && isSignedURL) {
    // If the backend returns a signed URL, redirect to it directly since it will contain the relay/redirect URL.
    window.location.assign(location);
  } else if (location && !isSignedURL) {
    const destination: string = redirectPath;
    redirectToSSOProvider(location, destination);
  } else {
    return res.json().then(
      // MFA
      (r: { token: string }) => dispatchLoginRequiresMfa(r.token),
    );
  }
  return;
}
