import { Path } from 'react-router';

/**
 * Adds search params to a destination
 * @param searchParams anything that can be passed to URLSearchParams constructor
 * @returns updated destination
 */
export function withHash(hash?: string) {
  return (path: Path) => {
    if (!hash) {
      return path;
    }

    return { ...path, hash };
  };
}
