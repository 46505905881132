import {
  getExperimentMetrics,
  getExperimentMetricsChart,
  getFlagsCreated,
  getFlagsCreatedChart,
  getFlagUsage,
  getMemberMetrics,
  getReleaseMetrics,
  getRolloutMetrics,
  getRolloutMetricsChart,
  getScores,
} from '@gonfalon/openapi';

import { createQueryOptions } from './internal/createQueryOptions';

export const getExperimentMetricsQuery = createQueryOptions(getExperimentMetrics);
export const getExperimentMetricsChartQuery = createQueryOptions(getExperimentMetricsChart);
export const getFlagsCreatedChartQuery = createQueryOptions(getFlagsCreatedChart);
export const getFlagsCreatedQuery = createQueryOptions(getFlagsCreated);
export const getFlagUsageQuery = createQueryOptions(getFlagUsage);
export const getMemberMetricsQuery = createQueryOptions(getMemberMetrics);
export const getReleaseMetricsQuery = createQueryOptions(getReleaseMetrics);
export const getRolloutMetricsQuery = createQueryOptions(getRolloutMetrics);
export const getRolloutMetricsChartQuery = createQueryOptions(getRolloutMetricsChart);
export const getScoresQuery = createQueryOptions(getScores);
