export const apiVersion = undefined;

import { GetTeamOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export interface GetTeamQueryParams extends Omit<GetTeamOpenAPIQueryParams, 'expand'> {
  expand?: Array<'members' | 'maintainers' | 'roles' | 'projects' | 'roleAttributes'>;
}

export function querySerializer(params: GetTeamQueryParams) {
  const searchParams = new URLSearchParams();

  if (params.expand) {
    searchParams.set('expand', params.expand.join(','));
  }

  return searchParams.toString();
}
