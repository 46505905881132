import { createSearchParams, URLSearchParamsInit } from 'react-router';

export function mergeSearchParams(...searchParams: URLSearchParamsInit[]) {
  if (searchParams.length === 1) {
    return createSearchParams(searchParams[0]);
  }

  const merged = new URLSearchParams();
  for (const searchParam of searchParams) {
    for (const [name, value] of createSearchParams(searchParam).entries()) {
      if (merged.has(name) && merged.get(name) === value) {
        continue;
      }
      merged.append(name, value);
    }
  }
  return merged;
}
