/* eslint-disable @typescript-eslint/no-explicit-any */
export type Writeable<T> = {
  -readonly [P in keyof T]: Writeable<T[P]>;
};

type ModifierBuilder<T, R> = {
  [K in keyof R]: R[K] extends (...args: infer P) => any ? (...args: P) => ModifierBuilder<T, R> : never;
} & T;

type ModifierFunction<T> = (record: Writeable<T>) => void;
type RecipeCreator<T> = (...args: any[]) => ModifierFunction<T>;

export function createRecordModifier<T>(recipeCreators: Record<string, RecipeCreator<T>>) {
  return (initialRecord: T) => {
    let currentRecord = { ...initialRecord };

    const builderAPI = { ...currentRecord } as ModifierBuilder<T, typeof recipeCreators>;

    Object.entries(recipeCreators).forEach(([name, createUpdate]) => {
      Object.defineProperty(builderAPI, name, {
        enumerable: false,
        value: (...args: any[]) => {
          const newRecord = structuredClone(currentRecord);
          createUpdate(...args)(newRecord);
          currentRecord = newRecord;
          Object.assign(builderAPI, currentRecord);
          return builderAPI;
        },
      });
    });

    return builderAPI;
  };
}
