// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router';
import { throwErrorsInRouterHooks } from '@gonfalon/dogfood-flags';
import { trackError } from '@gonfalon/telemetry';

/**
 * Type-safe function for reading the `:envKey` or `:environmentKey` URL parameter.
 * Will throw error if `:envKey` or `:environmentKey` is not present in the URL.
 */
// @ts-expect-error Technically, this function can return `undefined`. Once we remove the flagged code, it won't be able to.
export function useEnvironmentKey(): string {
  const shouldThrowError = throwErrorsInRouterHooks();
  const { envKey, environmentKey } = useParams();

  if (envKey) {
    return envKey;
  }

  if (environmentKey) {
    return environmentKey;
  }

  const error = new Error('Parameter "envKey" or "environmentKey" not present in URL');

  if (shouldThrowError) {
    throw error;
  } else {
    trackError(error);
  }
}
