import { Path } from 'react-router';
import {
  parseProjectContextSearchParams,
  ProjectContext,
  projectContextSearchParamsUpdater,
  projectContextToURLSearchParams,
} from '@gonfalon/context';

export function withNewProjectContext(projectContext: ProjectContext) {
  return (path: Path) => {
    const context = projectContextToURLSearchParams(projectContext);

    if (!context) {
      return path;
    }

    const searchParams = new URLSearchParams(path.search);

    const nextContext = parseProjectContextSearchParams(context);

    const nextSearchParams = projectContextSearchParamsUpdater(searchParams, nextContext);

    return {
      ...path,
      search: nextSearchParams.toString(),
    };
  };
}
