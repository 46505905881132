import { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import { noop } from '@gonfalon/es6-utils';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;

// Used to track each <Helmet/> instance.
function useId() {
  const id = useRef<number>();

  if (id.current === undefined) {
    id.current = getUniqueId();
  }

  return id.current;
}

type HelmetContextValue = { add(id: number, title: string): void; remove(id: number): void };

export const HelmetContext = createContext<HelmetContextValue>({ add: noop, remove: noop });

export type HelmetProviderProps = {
  children: ReactNode;
};

// TODO: remove this since it's unused
export function HelmetProvider({ children }: HelmetProviderProps) {
  const titles = useRef<Map<number, string>>(new Map());

  function updateTitle() {
    // disable title updates in lieu of new handle approach in new IA
    return;
  }

  function add(id: number, title: string) {
    titles.current.set(id, title);
    updateTitle();
  }

  function remove(id: number) {
    titles.current.delete(id);
    updateTitle();
  }

  const value = useMemo(
    () => ({
      add,
      remove,
    }),
    [],
  );

  return <HelmetContext.Provider value={value}>{children}</HelmetContext.Provider>;
}

export type HelmetProps = {
  title: string;
};

export function LegacyTitle({ title }: HelmetProps) {
  const ctx = useContext(HelmetContext);
  const id = useId();

  useEffect(() => {
    ctx.add(id, title);
    return () => ctx.remove(id);
  }, [title]);

  return null;
}
