import { createSuspenseQueryHook } from './internal/createSuspenseQueryHook';
import { experiments } from './internal/queries';

export const useSuspenseExperimentTraffic = createSuspenseQueryHook(
  ({
    projectKey,
    environmentKey,
    experimentKey,
    iterationId,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    iterationId: string;
  }) => experiments.detail({ projectKey, environmentKey, experimentKey })._ctx.traffic({ iterationId }),
);
