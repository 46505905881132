import { type URLSearchParamsInit, createSearchParams } from 'react-router';

import { mergeSearchParams } from './mergeSearchParams';

export function createCustomSearchParamsUpdater<T extends object>({
  parse,
  serialize,
}: {
  parse: (searchParams: URLSearchParams) => T;
  serialize: (t: T) => URLSearchParamsInit;
}): (searchParams: URLSearchParams, init: T | ((prev: T) => T)) => URLSearchParams {
  return (searchParams: URLSearchParams, init: T | ((prev: T) => T)) => {
    const currentParams = new URLSearchParams(searchParams);
    const currentParsed = parse(currentParams);
    const nextInit = typeof init === 'function' ? init(currentParsed) : init;

    const toRemove = createSearchParams(serialize(currentParsed));
    for (const [key, value] of toRemove.entries()) {
      if (value === '') {
        continue;
      }
      currentParams.delete(String(key));
    }

    return mergeSearchParams(serialize(nextInit), currentParams);
  };
}
