import { enableRoleAttributesUI } from '@gonfalon/dogfood-flags';
import { getMember, getMembers, patchMember } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { createQueryOptions } from './internal/createQueryOptions';

const getProfile = async () =>
  getMember({ id: 'me', query: { expand: enableRoleAttributesUI() ? 'roleAttributes' : undefined } });

export const memberProfileQuery = createQueryOptions(getProfile);
export const memberQuery = createQueryOptions(getMember);
export const membersQuery = createQueryOptions(getMembers);
export const usePatchMember = createMutationHook(patchMember, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: memberQuery.partialQueryKey() });
  },
});
