import { deleteMetricGroup } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { metricGroups } from './internal/queries';

export const useDeleteMetricGroup = createMutationHook(deleteMetricGroup, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: metricGroups.list._def });
  },
});
