import { datadogEnvironment, intercomFinAppAppId } from '@gonfalon/constants';
import { enableIntercomChatAI } from '@gonfalon/dogfood-flags';

import { initialBoot } from './initialBoot';
import { loadIntercomFinAppScript } from './loadIntercomFinAppScript';
import { removeIntercom } from './removeIntercom';
import { InitIntercomFinAppParams, IntercomFlowType } from './types';

export const openIntercomLauncher = (flowName?: IntercomFlowType) => {
  const appId = intercomFinAppAppId();
  if (!enableIntercomChatAI() || !appId) {
    return;
  }

  const options: InitIntercomFinAppParams = {
    identityVerificationEnabled: datadogEnvironment() === 'production',
    flowName,
  };

  if (window?.Intercom?.booted) {
    // eslint-disable-next-line new-cap
    window.Intercom('show');
    return; // No need to load intercom again
  }

  removeIntercom();
  loadIntercomFinAppScript(appId, options)
    .then(() => {
      initialBoot(appId, options);
      // eslint-disable-next-line new-cap
      window.Intercom('show');
    })
    .catch((error) => error);
};
